import React from 'react';
import { withLayout } from '../components/Layout';
import { graphql, PageProps } from 'gatsby';
import Helmet from 'react-helmet';
import { FixedMasthead } from '../components/Masthead/FixedMasthead.component';
import { PageHeader } from '../components/PageHeader/PageHeader.component';
import styled from '../styled-components';
import { Constrain } from '../components/ui';
import homepageSocialImage from '../images/homepage-social-image.jpg';
import { Notion } from '../generated/graphql-types';

const PageWrapper = styled.div`
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 9rem;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 170px;
        background-color: ${({ theme }) => theme.colors.electricBlue.color};
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
    }

    a {
        color: ${({ theme }) => theme.colors.deepBlue.color};
        transition: all 100ms;

        &:hover {
            text-decoration-color: ${({ theme }) =>
                theme.colors.electricBlue.color};
        }
    }     
`;

const CodeOfEthicsPage = ({ data }: PageProps<{ notion: Notion }>) => {
    return (
        <>
            <Helmet>
                <title>Code of ethics</title>
                <meta
                    name="description"
                    content="A guide for our team to operate in an ethical manner, in congruence with our company values."
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />

                <meta
                    name="twitter:image"
                    content={`https://wemakewaves.digital${homepageSocialImage}`}
                />
                <meta
                    property="og:image"
                    content={`https://wemakewaves.digital${homepageSocialImage}`}
                />
            </Helmet>

            <FixedMasthead />

            <PageHeader highlightColor="electricBlue" title="Code of ethics" />

            <PageWrapper>
                <Constrain type="narrow">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data.notion.content
                        }}
                    />
                </Constrain>
            </PageWrapper>
        </>
    );
};

export const query = graphql`
    query CodeOfEthicsQuery {
        notion {
            content
        }
    }
`;

export default withLayout(CodeOfEthicsPage);
